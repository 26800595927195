// ** Reducers Imports
import navbar from "./navbar"
import layout from "./layout"
import auth from "./authentication"
import employees from "../api/user"
import privilages from "../api/privilage"
import activities from "../api/activity"
import position from "../api/position"
import sectors from "../api/unitwork"
import workunits from "../api/workunitlist"
import structures from "../api/orgStructure"
import services from "../api/apimanagement"

const rootReducer = {
    auth,
    navbar,
    layout,
    employees,
    privilages,
    activities,
    position,
    sectors,
    workunits,
    structures,
    services
}

export default rootReducer
