import {
    Get,
    Post,
    Delete,
    Put,
    GetMarker,
    PostUpload
} from "../../core/request"
import { servicesURL, securityURL } from "../../core/url"

// GET
const getServices = (data) =>
    Get(`${securityURL.securityPrefix}/${servicesURL.services}?all`, data)

const getServicesList = (data) =>
    Get(`${securityURL.securityPrefix}/service?all`, data)

const createService = (data) =>
    Post(
        `${securityURL.securityPrefix}/${servicesURL.services}/${servicesURL.serviceCreate}`,
        data
    )

const updateService = (data) =>
    Put(
        `${securityURL.securityPrefix}/${servicesURL.services}`,
        data
    )

const deleteService = (data) =>
    Delete(
        `${securityURL.securityPrefix}/${servicesURL.services}?id=${data}`,
    )

const authAPI = {
    getServices,
    getServicesList,
    createService,
    updateService,
    deleteService
}

export default authAPI
