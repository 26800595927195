// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
// import axios from "axios"
import Api from "../../services/pages/workunitlist"

export const getWorkunit = createAsyncThunk(
    "sectors/getAll",
    async (params) => {
        const response = await Api.getWorkUnit(params)
        const resOtions = await response.data?.workunit?.map((e) => {
            return { ...e, value: e.id, label: e.name }
        })

        return {
            params: params,
            workunit: response.data.workunit,
            pagination: response.data.pagination,
            list: resOtions
        }
    }
)

export const getWrokUnitByLevel = createAsyncThunk(
    "sectors/getWorkUnitByLevel",
    async (params) => {
        const response = await Api.getWorkUnitbyLevel(params)
        return response?.data
    }
)

export const getWorkUnitDetail = createAsyncThunk(
    "sectors/getWorkUnitDetail",
    async (params) => {
        const response = await Api.getWorkUnitbyID(params)
        console.log("detail", response)
        return response?.data
    }
)

export const getWorkUnitFilter = createAsyncThunk(
    "sectors/getWorkUnitFilter",
    async (params) => {
        const response = await Api.getWorkunitFilter(params)
        // console.log("filter", response)
        return {
            params: params,
            workunit: response.data.workunit,
            pagination: response.data.pagination
            // list: resOtions
        }
    }
)

export const getWorkunitList = createAsyncThunk(
    "sectors/getAllList",
    async () => {
        const response = await Api.getWorkUnitList()
        // console.log("workunitlist", response)
        const resOtions = response.data?.map((e, i) => {
            return {
                ...e,
                key: i,
                value: e.id,
                label: e.name
                // workunit_level_id: data.workunit_level_id
            }
        })

        // console.log("list", resOtions)

        // console.log({ workunit: response?.data, workunitopt: resOtions })
        return { workunit: response?.data, workunitopt: resOtions }
    }
)

export const getWorkUnitLevel = createAsyncThunk(
    "employees/getWorkUnitLevel",
    async () => {
        const response = await Api.getWorkUnitLevel()

        const resOtions = response.data?.workunit_level.map((e, i) => {
            return {
                ...e,
                key: i,
                value: e.id,
                label: e.name
            }
        })

        return resOtions
    }
)

export const uploadPhoto = createAsyncThunk(
    "sectors/uploadPhoto",
    async (sendbody, { dispatch, getState }) => {
        const response = await Api.uploadLogoWorkUnit(sendbody)
        console.log("upload logo", response)
        await dispatch(getWorkunit())
        return response.data
    }
)

export const updatePhoto = createAsyncThunk(
    "sectors/uploadPhoto",
    async (sendbody, { dispatch, getState }) => {
        const response = await Api.updateLogoWorkUnit(sendbody)
        await dispatch(getWorkunit())
        return response.data
    }
)

export const createWorkunit = createAsyncThunk(
    "sectors/createWorkunit",
    async (sector, { dispatch, getState }) => {
        const response = await Api.createWorkunit(sector)
        await dispatch(getWorkunit())
        return response.data
    }
)

export const updateWorkunit = createAsyncThunk(
    "sectors/updateWorkunit",
    async (sector, { dispatch, getState }) => {
        const response = await Api.updateWorkUnit(sector)
        await dispatch(getWorkunit())
        return response.data
    }
)

export const deleteWorkunit = createAsyncThunk(
    "sectors/deleteWorkunit",
    async (id, { dispatch, getState }) => {
        await Api.deleteWorkunit(id)
        await dispatch(getWorkunit())
        return id
    }
)

export const workunitSlice = createSlice({
    name: "workunits",
    initialState: {
        params: null,
        data: [],
        listparent: [],
        pagination: {},
        selected: null,
        dataMap: null,
        workunitlevel: [],
        workunitOpt: [],
        detail: {},
        workunitlist: []
    },
    reducers: {
        selectWorkunit: (state, action) => {
            if (action.payload === null) {
                state.selected = null
            } else {
                state.selected = action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getWorkunit.fulfilled, (state, action) => {
                state.data = action.payload.workunit
                state.pagination = action.payload.pagination
                state.params = action.payload.params
                state.listparent = action.payload.list
            })
            .addCase(getWorkUnitFilter.fulfilled, (state, action) => {
                state.data = action.payload.workunit
                state.pagination = action.payload.pagination
                // state.params = action.payload.params
                state.listparent = action.payload.list
            })
            .addCase(getWorkunitList.fulfilled, (state, action) => {
                state.dataMap = action.payload.workunit
                state.workunitlist = action.payload.workunitopt
                state.workunitOpt = action.payload.workunitopt
            })
            .addCase(getWorkUnitLevel.fulfilled, (state, action) => {
                state.workunitlevel = action.payload
            })
            .addCase(getWorkUnitDetail.fulfilled, (state, action) => {
                state.detail = action.payload
            })
            .addCase(getWrokUnitByLevel.fulfilled, (state, action) => {
                state.data = action.payload.workunit
                state.pagination = action.payload.pagination
            })
    }
})

export const { selectWorkunit } = workunitSlice.actions

export default workunitSlice.reducer
