import {
    Get,
    Post,
    Delete,
    Put,
    GetMarker,
    PostUpload
} from "../../core/request"
import { employeeURL } from "../../core/url"

// GET
const getSector = (data) =>
    Get(`${employeeURL.employeePrefix}/${employeeURL.sector}`, data)

const getListSector = (data) =>
    Get(
        `${employeeURL.employeePrefix}/${employeeURL.sector}/${employeeURL.sectorParent}`,
        data
    )

const createSector = (data) =>
    Post(
        `${employeeURL.employeePrefix}/${employeeURL.sector}/${employeeURL.sectorCreate}`,
        data
    )

const updateSector = (data) =>
    Put(
        `${employeeURL.employeePrefix}/${employeeURL.sector}/${employeeURL.sectorUpdate}`,
        data
    )

const deleteSector = (data) =>
    Delete(
        `${employeeURL.employeePrefix}/${employeeURL.sector}/${employeeURL.sectorDelete}`,
        data
    )

const authAPI = {
    getSector,
    getListSector,
    createSector,
    updateSector,
    deleteSector
}

export default authAPI
