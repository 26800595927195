// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"
import employeeAPI from "../../services/pages/user"
import authAPI from "../../services/pages/login"

export const getData = createAsyncThunk("employees/getAll", async (params) => {
    const response = await employeeAPI.getEmployee(params)
    return {
        params: params,
        total: response.data.pagination.data_total,
        allData: response.data.employee,
        data: response.data.employee,
        pagination: response.data.pagination
    }
})

export const createEmployee = createAsyncThunk(
    "employees/addEmployee",
    async (employee, { dispatch, getState }) => {
        const response = await employeeAPI.createEmployee(employee)
        // console.log("response", response)
        await dispatch(getData(getState().employees.params))
        return response?.data
    }
)

export const filterEmployee = createAsyncThunk(
    "employees/filterEmployee",
    async (employee, { dispatch, getState }) => {
        const response = await employeeAPI.filterEmployee(employee)
        // await dispatch(getData(getState().employees.params))
        return {
            data: response.data.employee,
            pagination: response.data.pagination
        }
    }
)

export const createAccount = createAsyncThunk(
    "employees/createAccount",
    async (sendBody, { dispatch, getState }) => {
        const response = await authAPI.createUser(sendBody)
        // console.log(response)
        await dispatch(getData(getState().employees.params))
        return response.data
    }
)

export const updateStatusEmployee = createAsyncThunk(
    "employees/updateStatusEmployee",
    async (employee, { dispatch, getState }) => {
        const response = await employeeAPI.updateEmpoleeStatus(employee)
        // console.log("succes update", response, employee)
        await dispatch(getData(getState().employees.params))
        return response
    }
)

export const updateEmployee = createAsyncThunk(
    "employees/updateEmployee",
    async (employee, { dispatch, getState }) => {
        const response = await employeeAPI.updateEmployee(employee)
        // console.log("response", response)
        await dispatch(getData(getState().employees.params))
        return response?.data
    }
)

export const uploadPhoto = createAsyncThunk(
    "employees/uploadPhoto",
    async (employee, { dispatch, getState }) => {
        const response = await employeeAPI.uploadPhotoEmployee(employee)
        // console.log("res upload", response)
        await dispatch(getData(getState().employees.params))
        return response.data
    }
)

export const updatePhoto = createAsyncThunk(
    "employees/updatePhoto",
    async (employee, { dispatch, getState }) => {
        await employeeAPI.updatePhotoEmployee(employee)
        await dispatch(getData(getState().params))
        return employee
    }
)

export const getDetailEmployee = createAsyncThunk(
    "employees/getEmployee",
    async (id) => {
        const response = await axios.get("/api/users/user", { id })
        return response.data.user
    }
)

export const deleteDataEmployee = createAsyncThunk(
    "employees/deleteEmployee",
    async (id, { dispatch, getState }) => {
        const response = await employeeAPI.deleteEmployee({ id })
        await dispatch(getData(getState().employees.params))
        return id
    }
)

export const getDataUserGroups = createAsyncThunk(
    "employees/getUserGroups",
    async () => {
        const response = await authAPI.privilageUser()
        // console.log("response", response)
        const resOtions = response.data?.groups.map((e) => {
            return { value: e.id, label: e.title, status: e.status }
        })
        return resOtions
    }
)

export const getDataWorkunit = createAsyncThunk(
    "employees/getWorkunit",
    async () => {
        const response = await employeeAPI.getWorkUnitList()
        const resOtions = response.data?.map((e, i) => {
            return {
                key: i,
                value: e.id,
                label: e.name,
                workunit_level_id: e.workunit_level_id,
                workunit_level: e.workunit_level
            }
        })
        return resOtions
    }
)

export const getDataSector = createAsyncThunk(
    "employees/getSector",
    async (sendbody) => {
        const response = await employeeAPI.getSector(sendbody)
        const resOtions = response.data?.sector?.map((e) => {
            return { value: e.id, label: e.name }
        })
        return resOtions
    }
)

export const getDataWorkunitLevel = createAsyncThunk(
    "employees/getWorkunitLevel",
    async (sendbody) => {
        const response = await employeeAPI.getWorkunitLevel(sendbody)
        console.log(response, "workunitlevel")
        const resOptions = response.data?.workunit_level?.map((e) => {
            return { value: e.id, label: e.name }
        })
        return resOptions
    }
)

export const getDataPosition = createAsyncThunk(
    "employees/getPosition",
    async (sendbody) => {
        const response = await employeeAPI.getPositionList(sendbody)
        const resOtions = response.data.map((e) => {
            return { value: e.id, label: e.name }
        })
        return resOtions
    }
)

export const employeeSlice = createSlice({
    name: "employees",
    initialState: {
        data: [],
        pagination: {},
        workunitlevel: [],
        workunits: [],
        sectors: [],
        positions: [],
        privilages: [],
        total: 1,
        params: {},
        allData: [],
        selected: null,
        error: null
    },
    reducers: {
        selectEmployee: (state, action) => {
            if (action.payload === null) {
                state.selected = null
            } else {
                state.selected = action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.pagination = action.payload.pagination
                state.allData = action.payload.data
                state.total = action.payload.total
                state.params = action.payload.params
            })
            .addCase(getDataWorkunitLevel.fulfilled, (state, action) => {
                state.workunitlevel = action.payload
            })
            .addCase(filterEmployee.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.pagination = action.payload.pagination
            })
            .addCase(getDataWorkunit.fulfilled, (state, action) => {
                state.workunits = action.payload
            })
            .addCase(getDataSector.fulfilled, (state, action) => {
                state.sectors = action.payload
            })
            .addCase(getDataPosition.fulfilled, (state, action) => {
                state.positions = action.payload
            })
            .addCase(getDataUserGroups.fulfilled, (state, action) => {
                state.privilages = action.payload
            })
            .addCase(createEmployee.rejected, (state, action) => {
                // const { requestId } = action.meta
                state.error = action.error
                // console.log("action.error", action)
                // console.log("state error", state)
            })
            .addCase(updateEmployee.rejected, (state, action) => {
                // const { requestId } = action.meta
                state.error = action.error
                // console.log("action.error", action)
                // console.log("state error", state)
            })
    }
})

export const { selectEmployee } = employeeSlice.actions

export default employeeSlice.reducer
