import {
    Get,
    Post,
    Delete,
    Put,
    GetMarker,
    PostUpload
} from "../../core/request"

import { 
    securityURL 
} from "../../core/url"

// GET
const getSecurityLog    = (data) => Get(`${securityURL.securityPrefix}/${securityURL.securityLog}`, data)

const getAllSecurityLog = (data) => Get(`${securityURL.securityPrefix}/${securityURL.securityLog}?all`, data)

const securityAPI       = { getSecurityLog, getAllSecurityLog }

export default securityAPI
