import { Get, Post, Delete, Put } from "../../core/request"
import { authURL } from "../../core/url"

//POST
const loginUser = (data, otp) =>
    Post(`${authURL.authPrefix}/${authURL.authLogin}`, data)

const loginUserWithOtp = (data, otp) =>
    Post(`${authURL.authPrefix}/${authURL.authLogin}?otp=${otp}`, data)

const privilageUser = (data, otp) =>
    Get(`${authURL.authPrefix}/${authURL.authGroups}?all`, data)

const createUser = (data) =>
    Post(
        `${authURL.authPrefix}/${authURL.authUser}/${authURL.authCreateUser}`,
        data
    )

const logOutUser = (data) => {
    Post(`${authURL.authPrefix}/${authURL.authLogout}`, data)
}

const forgotPassword = (data) => {
    Post(
        `${authURL.authPrefix}/${authURL.authUser}/${authURL.authPassword}`,
        data
    )
}

const getUserbyToken = (data) => {
    Get(
        `${authURL.authPrefix}/${authURL.authUser}/${authURL.authPassword}?forget=${data}`
    )
}

const chagePassword = (data) => {
    Put(
        `${authURL.authPrefix}/${authURL.authUser}/${authURL.authPassword}`,
        data
    )
}

const updateUserStatus = (data) => {
    Put(`${authURL.authPrefix}/${authURL.authUser}/${authURL.authStatus}`, data)
}

const authAPI = {
    //Post
    loginUser,
    privilageUser,
    logOutUser,
    createUser,
    forgotPassword,
    getUserbyToken,
    chagePassword,
    updateUserStatus,
    loginUserWithOtp
}

export default authAPI
