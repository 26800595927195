// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"
import Api from "../../services/pages/position"
import employeeAPI from "../../services/pages/user"

export const getAllData = createAsyncThunk("positions/getAll", async () => {
    const response = await Api.getPosition()
    return response?.data?.position
})

export const getData = createAsyncThunk("positions/getData", async (params) => {
    // console.log("params getdata ", params)
    const response = await Api.getPosition(params)
    // console.log("response", response.data)
    return {
        params: params,
        data: response?.data?.position,
        pagination: response?.data?.pagination
    }
})

export const getDataFilter = createAsyncThunk("positions/getData", async (params) => {
    // console.log("params getdata ", params)
    const response = await Api.getPositionFilter(params)
    // console.log("response", response.data)
    return {
        params: params,
        data: response?.data?.position,
        pagination: response?.data?.pagination
    }
})

export const getDetail = createAsyncThunk("positions/getDetail", async (id) => {
    const response = await Api.getDetailPosition({ id })
    return response.data
})

export const getParentsPosition = createAsyncThunk(
    "positions/parentPosition",
    async () => {
        const response = await Api.getParentsPosition()
        const resOtions = response.data?.map((e) => {
            return {
                ...e,
                value: e.id,
                label: e.name
            }
        })
        return resOtions
    }
)

export const getDataWorkunitLevel = createAsyncThunk(
    "positions/getWorkunitLevel",
    async () => {
        const response = await employeeAPI.getWorkunitLevel()
        const resOtions = response.data?.workunit_level?.map((e, i) => {
            return { ...e, value: e.id, label: e.name, key: i }
        })
        return resOtions
    }
)

export const getDataSector = createAsyncThunk(
    "positions/getSector",
    async (sendbody) => {
        const response = await employeeAPI.getSector(sendbody)
        const resOtions = response.data?.sector?.map((e, i) => {
            return { ...e, value: e.id, label: e.name, key: i }
        })
        return resOtions
    }
)

export const createPosition = createAsyncThunk(
    "positions/createPosition",
    async (position, { dispatch, getState }) => {
        let response = await Api.createPosition(position)
        // console.log(response)
        await dispatch(getData(getState().position.params))
        // await dispatch(getAllData())
        return position
    }
)

export const updatePosition = createAsyncThunk(
    "positions/updatePosition",
    async (position, { dispatch, getState }) => {
        const response = await Api.updatePosition(position)
        // console.log("response", response)
        await dispatch(getData(getState().params))
        await dispatch(getAllData())
        return response.data
    }
)

export const deletePosition = createAsyncThunk(
    "positions/deletePosition",
    async (id, { dispatch, getState }) => {
        await Api.deletePosition(id)
        await dispatch(getData(getState().params))
        await dispatch(getAllData())
        return id
    }
)

export const positionSlice = createSlice({
    name: "positions",
    initialState: {
        data: [],
        pagination: {},
        parentsPosition: [],
        workunitlevel: [],
        sectors: [],
        selectedPosition: null,
        selected: null
    },
    reducers: {
        selectPosition: (state, action) => {
            if (action.payload === null) {
                state.selected = null
            } else {
                state.selected = action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.pagination = action.payload.pagination
            })
            .addCase(getDetail.fulfilled, (state, action) => {
                state.selectedPosition = action.payload
            })
            .addCase(getParentsPosition.fulfilled, (state, action) => {
                state.parentsPosition = action.payload
            })
            .addCase(getDataWorkunitLevel.fulfilled, (state, action) => {
                state.workunitlevel = action.payload
            })
            .addCase(getDataSector.fulfilled, (state, action) => {
                state.sectors = action.payload
            })
    }
})

export const { selectPosition } = positionSlice.actions

export default positionSlice.reducer
