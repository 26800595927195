import {
    Get,
    Post,
    Delete,
    Put,
    GetMarker,
    PostUpload
} from "../../core/request"
import { employeeURL } from "../../core/url"

// GET
const getOrgStructure = (data) =>
    Post(
        `${employeeURL.employeePrefix}/${employeeURL.position}/${employeeURL.orgFilter}`,
        data
    )

const orgAPI = {
    getOrgStructure
}

export default orgAPI
