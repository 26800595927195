import {
    Get,
    Post,
    Delete,
    Put,
    GetMarker,
    PostUpload
} from "../../core/request"
import { authURL } from "../../core/url"

// GET

const getAllPrivilageRole = (data) =>
    Get(`${authURL.authPrefix}/${authURL.authGroups}?all`, data)

const getPrivilageRole = (data) =>
    Get(`${authURL.authPrefix}/${authURL.authGroups}`, data)

const getDetail = (data) =>
    Post(
        `${authURL.authPrefix}/${authURL.authRole}/${authURL.authRoleDetail}`,
        data
    )

const createPrivilageRole = (data) =>
    Post(
        `${authURL.authPrefix}/${authURL.authRole}/${authURL.authGroupsCreate}`,
        data
    )

const updatePrivilageRole = (data) =>
    Put(
        `${authURL.authPrefix}/${authURL.authRole}/${authURL.authGroupsUpdate}`,
        data
    )

const deletePrivilageRole = (data) =>
    Delete(
        `${authURL.authPrefix}/${authURL.authGroups}/${authURL.authGroupsDelete}`,
        data
    )

const getByUUIPrivilageRole = (data) =>
    Post(
        `${authURL.authPrefix}/${authURL.authGroups}/${authURL.authGroupByUUI}`,
        data
    )

const getTemplateRole = (data) =>
    Get(
        `${authURL.authPrefix}/${authURL.authRole}/${authURL.authRoleTemplate}`,
        data
    )

const authAPI = {
    getAllPrivilageRole,
    getPrivilageRole,
    createPrivilageRole,
    updatePrivilageRole,
    deletePrivilageRole,
    getByUUIPrivilageRole,
    getTemplateRole,
    getDetail
}

export default authAPI
