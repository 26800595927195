// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"

// ** UseJWT import to get config
import useJwt from "@src/auth/jwt/useJwt"

import authAPI from "../services/pages/login"

const config = useJwt.jwtConfig

const initialUser = () => {
    const item = window.localStorage.getItem("userData")
    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : {}
}

const initialMenu = () => {
    const item = window.localStorage.getItem("menu")
    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
    name: "authentication",
    initialState: {
        userData: initialUser(),
        menu: initialMenu(),
    },
    reducers: {
        handleLogin: (state, action) => {
            state.userData = action.payload

            state[config.storageTokenKeyName]           = action.payload[config.storageTokenKeyName]
            state[config.storageRefreshTokenKeyName]    = action.payload[config.storageRefreshTokenKeyName]

            if (process.env.NODE_ENV && process.env.NODE_ENV !== "production") {

                localStorage.setItem(
                    config.storageTokenKeyName,
                    JSON.stringify(action.payload.accessToken)
                )
                localStorage.setItem(
                    config.storageRefreshTokenKeyName,
                    JSON.stringify(action.payload.refreshToken)
                )
            }

            delete action.payload['accessToken']
            delete action.payload["refreshToken"]

            localStorage.setItem("userData", JSON.stringify(action.payload))
        },
        handleLogout: (state) => {
            state.userData = {}
            state[config.storageTokenKeyName] = null
            state[config.storageRefreshTokenKeyName] = null
            // ** Remove user, accessToken & refreshToken from localStorage
            // authAPI
            //     .logOutUser()
            //     .then((res) => {
            // console.log("res logout", res.data)
            localStorage.removeItem("userData")
            localStorage.removeItem(config.storageTokenKeyName)
            localStorage.removeItem(config.storageRefreshTokenKeyName)
            // })
            // .catch((err) => {
            //     console.log(err)
            // })
        }
    }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
