// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"
import Api from "../../services/pages/apimanagement"

function unravel(obj) {
    var out = []
    var added = false
    for (var i in obj) {
        if (obj[i] instanceof Array) {
            for (var j in obj[i]) {
                var r = unravel(obj[i][j])
                for (var k in r) {
                    var a = {}
                    for (var key in obj) {
                        // make copy of obj
                        a[key] = obj[key]
                    }
                    a[i] = r[k]
                    added = true
                    out.push(a)
                }
            }
        }
    }
    if (!added) {
        out.push(obj)
    }
    return out
}

export const getAllServices = createAsyncThunk(
    "service/getAll",
    async (params) => {
        const response = await Api.getServices(params)
        const resdata = response.data

        // var op = []
        // for (var i in resdata) op = op.concat(unravel(resdata[i]))

        // const resOPt = response?.data.map((item) => {
        //     var op = []
        //     for (var i in item) op = op.concat(unravel(item[i]))
        //     console.log("op", op)
        //     // return op
        // })
        //

        // console.log(op)

        return { data: resdata, pagination: response.data?.pagination }
    }
)

export const createService = createAsyncThunk(
    "service/createService",
    async (sendBody, { dispatch, getState }) => {
        let response = await Api.createService(sendBody)
        await dispatch(getAllServices(getState()))
        return response
    }
)

export const updateService = createAsyncThunk(
    "service/updateService",
    async (sendBody, { dispatch, getState }) => {
        const response = await Api.updateService(sendBody)
        // console.log("response", response)
        await dispatch(getAllServices())
        return response.data
    }
)

export const deleteService = createAsyncThunk(
    "service/deleteService",
    async (id, { dispatch, getState }) => {
        await Api.deleteService(id)
        await dispatch(getAllServices())
        return id
    }
)

export const serviceSlice = createSlice({
    name: "positions",
    initialState: {
        data: [],
        selected: null,
        pagination: []
    },
    reducers: {
        selectService: (state, action) => {
            if (action.payload === null) {
                state.selected = null
            } else {
                state.selected = action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllServices.fulfilled, (state, action) => {
            ;(state.data = action.payload.data),
                (state.pagination = action.payload.pagination)
        })
    }
})

export const { selectService } = serviceSlice.actions

export default serviceSlice.reducer
