// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"
import Api from "../../services/pages/unitwork"
// import employeeAPI from "../../services/pages/user"

export const getAllSector = createAsyncThunk(
    "sectors/getAll",
    async (params) => {
        const response = await Api.getSector(params)
        const resOtions = await response.data?.sector?.map((e) => {
            return { ...e, value: e.id, label: e.name }
        })
        return {
            params: params,
            sector: response.data.sector,
            pagination: response.data.pagination,
            list: resOtions
        }
    }
)

export const getListSector = createAsyncThunk(
    "sectors/getList",
    async (params) => {
        const response = await Api.getListSector(params)
        const resOtions = response.data?.map((e) => {
            return { ...e, value: e.id, label: e.name }
        })
        return resOtions
    }
)

export const createSector = createAsyncThunk(
    "positions/createSector",
    async (sector, { dispatch, getState }) => {
        const response = await Api.createSector(sector)
        // console.log("response", response)
        await dispatch(getAllSector(getState().params))
        return sector
    }
)

export const updateSector = createAsyncThunk(
    "positions/updateSector",
    async (sector, { dispatch, getState }) => {
        const response = await Api.updateSector(sector)
        await dispatch(getAllSector(getState().params))
        return response.data
    }
)

export const deleteSector = createAsyncThunk(
    "positions/deleteSector",
    async (id, { dispatch, getState }) => {
        await Api.deleteSector(id)
        await dispatch(getAllSector(getState().params))
        return id
    }
)

export const sectorSlice = createSlice({
    name: "sectors",
    initialState: {
        params: null,
        data: [],
        listparent: [],
        pagination: {},
        selected: null
    },
    reducers: {
        selectSector: (state, action) => {
            if (action.payload === null) {
                state.selected = null
            } else {
                state.selected = action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllSector.fulfilled, (state, action) => {
            state.data = action.payload.sector
            state.pagination = action.payload.pagination
            state.params = action.payload.params
            state.listparent = action.payload.list
        })
    }
})

export const { selectSector } = sectorSlice.actions

export default sectorSlice.reducer
