// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import Api from "../../services/pages/activity"
import employeeAPI from "../../services/pages/user"
import ApiWK from "../../services/pages/workunitlist"

export const getData = createAsyncThunk(
    "securityApp/getAll",
    async (params) => {
        const response = await Api.getSecurityLog(params)
        let pagination = {
            has_next: response.next == "" ? false : true,
            has_previous: response.prev == "" ? false : true,
            current_page: response.current_page,
            total_page: response.total_page,
            data_total: response.total
        }
        // console.log(response)
        return {
            params: params,
            data: response?.data.result,
            pagination
        }
    }
)

export const getAllData = createAsyncThunk(
    "securityApp/getAllData",
    async (params) => {
        const response = await Api.getAllSecurityLog(params)

        return {
            params: params,
            data: response?.data
        }
    }
)

export const getDataWorkunit = createAsyncThunk(
    "securityApp/getWorkunit",
    async () => {
        const response = await employeeAPI.getWorkUnit()
        const resOtions = response.data?.workunit?.map((e, i) => {
            return {
                key: i,
                value: e.id,
                label: e.name,
                workunit_level_id: e.workunit_level_id,
                workunit_level: e.workunit_level
            }
        })
        return resOtions
    }
)

export const getWorkunitList = createAsyncThunk(
    "securityApp/getWorkUnitList",
    async () => {
        const response = await ApiWK.getWorkUnitList()
        // console.log("workunitlist", response)
        const resOtions = response.data?.map((e, i) => {
            return {
                ...e,
                key: i,
                value: e.id,
                label: e.name
                // workunit_level_id: data.workunit_level_id
            }
        })

        // console.log("list", resOtions)

        // console.log({ workunit: response?.data, workunitopt: resOtions })
        return { workunit: response?.data, workunitopt: resOtions }
    }
)

export const securitySlice = createSlice({
    name: "securityApp",
    initialState: {
        data: [],
        pagination: {},
        workunits: [],
        workunitlist: [],
        alldata: []
    },
    reducers: {
        selectSecurity: (state, action) => {
            if (action.payload === null) {
                state.selected = null
            } else {
                state.selected = action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.pagination = action.payload.pagination
            })
            .addCase(getAllData.fulfilled, (state, action) => {
                state.alldata = action.payload.data
            })
            .addCase(getDataWorkunit.fulfilled, (state, action) => {
                state.workunits = action.payload
            })
            .addCase(getWorkunitList.fulfilled, (state, action) => {
                state.workunitlist = action.payload.workunitopt
            })
    }
})

export const { selectSecurity } = securitySlice.actions

export default securitySlice.reducer
