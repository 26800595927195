/*
    ####################
    #SEPARATOR API AUTH#
    ####################
*/

export const authURL = {
    authPrefix: "auth",

    authUser: "user",
    authLogin: "login",
    authLogout: "logout",
    authRequest: "request",
    authEndpoint: "endpoint",
    authServices: "services",

    // groups
    authGroups: "groups",
    authGroupsCreate: "create",
    authGroupsUpdate: "update",
    authGroupsDelete: "delete",
    authGroupByUUI: "by-uuid",

    authMenu: "menu",
    authInsert: "insert",
    authGetUser: "list",
    authCreateUser: "create",
    authUpdateUser: "update",
    authDeleteUser: "delete",
    authTokenCheck: "token/check",
    authDetailUser: "detail",

    authReset: "reset",
    authCheck: "check",
    authStatus: "status",
    authPassword: "password",

    //Role
    authRole: "role",
    authRoleTemplate: "template",
    authRoleDetail: "detail"
}

export const employeeURL = {
    employeePrefix: "employee-biodata",

    employee: "employee",
    updateEmployee: "update",
    createEmployee: "create",
    deleteEmployee: "delete",
    uploadPhoto: "upload-photo",
    updatePhoto: "update-photo",
    filterEmployee: "filter",
    employeeList: "list",

    workunitLevel: "workunit-level",
    workunit: "workunit",
    workunitByLevel: "by-level",
    sector: "sector",
    position: "position",

    // position
    positionDetail: "detail",
    positionCreate: "create",
    positionUpdate: "update",
    positionDelete: "delete",
    positionParent: "list",

    // sector
    sectorCreate: "create",
    sectorUpdate: "update",
    sectorDelete: "delete",
    sectorParent: "list",

    // workunit
    workunitDetail: "detail",
    workunitCreate: "create",
    workunitUpdate: "update",
    workunitDelete: "delete",
    workunitList: "list",
    workunitFilter: "filter",
    workunitUploadLogo: "upload-logo",
    workunitUpdateLogo: "update-logo",

    // org structure
    orgFilter: "filter"
}

export const securityURL = {
    securityPrefix: "api-gw",

    securityLog: "activities"
}

export const servicesURL = {
    services: "endpoint",
    serviceCreate: "create",
    serviceUpdate: "update",
    serviceDelete: "delete"
}

export const notificationURL = {
    notification: "notification"
}

export const rankingURL = {
    de: "document-editor",
    ranking: "ranking"
}

export const chatURL = {
    chatPrefix: "communication",

    messages: "messages",
    room: "room",
    broadcast: "broadcast",
    revoke: "revoke",
    forMe: "for-me",

    attachments: "attachments",

    room: "room",
    byUuid: "by-uuid",
    archive: "archive",
    broadcast: "broadcast",
    read: "read",
    unRead: "un-read",
    user: "user",
    admin: "admin",

    users: "users"
}

export const helpURL = {
    helpPrefix  : "help-and-guide",
    manualBook  : "manual-book",
    category    : "category"
}
