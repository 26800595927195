import {
    Get,
    Post,
    Delete,
    Put,
    GetMarker,
    PostUpload
} from "../../core/request"
import { employeeURL } from "../../core/url"

// GET
const getWorkUnit = (data) =>
    Get(`${employeeURL.employeePrefix}/${employeeURL.workunit}`, data)

const getWorkUnitList = (data) =>
    Get(
        // `${employeeURL.employeePrefix}/${employeeURL.workunit}/${employeeURL.workunitList}`,
        `document-editor/work-unit`,
        data
    )

const getWorkUnitLevel = (data) =>
    Get(`${employeeURL.employeePrefix}/${employeeURL.workunitLevel}`, data)

const getWorkUnitbyLevel = (data) =>
    Post(
        `${employeeURL.employeePrefix}/${employeeURL.workunit}/${employeeURL.workunitByLevel}`,
        data
    )

const getWorkunitFilter = (data) =>
    Post(
        `${employeeURL.employeePrefix}/${employeeURL.workunit}/${employeeURL.workunitFilter}`,
        data
    )

const getWorkUnitbyID = (data) =>
    Post(
        `${employeeURL.employeePrefix}/${employeeURL.workunit}/${employeeURL.workunitDetail}`,
        data
    )

const createWorkunit = (data) =>
    Post(
        `${employeeURL.employeePrefix}/${employeeURL.workunit}/${employeeURL.workunitCreate}`,
        data
    )

const uploadLogoWorkUnit = (data) =>
    PostUpload(
        `${employeeURL.employeePrefix}/${employeeURL.workunit}/${employeeURL.workunitUploadLogo}`,
        data
    )

const updateLogoWorkUnit = (data) =>
    PostUpload(
        `${employeeURL.employeePrefix}/${employeeURL.workunit}/${employeeURL.workunitUpdateLogo}`,
        data
    )

const updateWorkUnit = (data) =>
    Put(
        `${employeeURL.employeePrefix}/${employeeURL.workunit}/${employeeURL.workunitUpdate}`,
        data
    )

const deleteWorkunit = (data) =>
    Delete(
        `${employeeURL.employeePrefix}/${employeeURL.workunit}/${employeeURL.workunitDelete}`,
        data
    )

const authAPI = {
    getWorkUnit,
    createWorkunit,
    updateWorkUnit,
    deleteWorkunit,
    getWorkUnitList,
    getWorkUnitLevel,
    getWorkUnitbyLevel,
    getWorkunitFilter,
    uploadLogoWorkUnit,
    updateLogoWorkUnit,
    getWorkUnitbyID
}

export default authAPI
