// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"
import Api from "../../services/pages/privilage"

export const getData = createAsyncThunk("privilages/getAll", async (params) => {
    const response = await Api.getPrivilageRole(params)
    return {
        params: params,
        data: response?.data?.groups,
        pagination: response?.data?.pagination
    }
})

export const getDetail = createAsyncThunk(
    "privilages/getDetail",
    async (body) => {
        const response = await Api.getDetail(body)
        return response?.data
    }
)

export const getTemplate = createAsyncThunk(
    "privilages/getTemplate",
    async () => {
        const response = await Api.getTemplateRole()
        const resOtions = response.data?.map((e) => {
            return { ...e, value: e.id, label: e.name }
        })
        return resOtions
    }
)

export const createPrivilage = createAsyncThunk(
    "privilages/createPrivilage",
    async (sendBody, { dispatch, getState }) => {
        let response = await Api.createPrivilageRole(sendBody)
        // console.log(response)
        await dispatch(getData(getState().position.params))
        // await dispatch(getAllData())
        return sendBody
    }
)

export const updatePrivilage = createAsyncThunk(
    "privilages/updatePrivilage",
    async (sendbody, { dispatch, getState }) => {
        const response = await Api.updatePrivilageRole(sendbody)
        await dispatch(getData(getState().position.params))
        return response.data
    }
)

export const deletePrivilageRole = createAsyncThunk(
    "privilages/deletePrivilage",
    async (id, { dispatch, getState }) => {
        await Api.deletePrivilageRole(id)
        await dispatch(getData(getState().params))
        return id
    }
)

export const privilageSlice = createSlice({
    name: "privilages",
    initialState: {
        data: [],
        pagination: {},
        templates: [],
        params: {},
        menus: []
    },
    reducers: {
        selectPrivilage: (state, action) => {
            if (action.payload === null) {
                state.selected = null
            } else {
                state.selected = action.payload
                state.menus = action.payload.menus
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                ;(state.params = action.payload.params),
                    (state.pagination = action.payload.pagination)
            })
            .addCase(getTemplate.fulfilled, (state, action) => {
                state.templates = action.payload
            })
    }
})

export const { selectPrivilage } = privilageSlice.actions

export default privilageSlice.reducer
