import {
    Get,
    Post,
    Delete,
    Put,
    GetMarker,
    PostUpload
} from "../../core/request"
import { employeeURL } from "../../core/url"

// GET
const getPosition = (data) =>
    Get(`${employeeURL.employeePrefix}/${employeeURL.position}`, data)

const getPositionFilter = (data) =>
    Post(`${employeeURL.employeePrefix}/${employeeURL.position}/filter`, data)

const getDetailPosition = (data) =>
    Post(
        `${employeeURL.employeePrefix}/${employeeURL.position}/${employeeURL.positionDetail}`,
        data
    )

const getParentsPosition = (data) =>
    Get(
        `${employeeURL.employeePrefix}/${employeeURL.position}/${employeeURL.positionParent}`,
        data
    )

const createPosition = (data) =>
    Post(
        `${employeeURL.employeePrefix}/${employeeURL.position}/${employeeURL.positionCreate}`,
        data
    )

const updatePosition = (data) =>
    Put(
        `${employeeURL.employeePrefix}/${employeeURL.position}/${employeeURL.positionUpdate}`,
        data
    )

const deletePosition = (data) =>
    Delete(
        `${employeeURL.employeePrefix}/${employeeURL.position}/${employeeURL.positionDelete}`,
        data
    )

const authAPI = {
    getPosition,
    getPositionFilter,
    getDetailPosition,
    getParentsPosition,
    createPosition,
    updatePosition,
    deletePosition
}

export default authAPI
