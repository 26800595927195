// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"
import Api from "../../services/pages/position"
import orgAPI from "../../services/pages/orgstructure"

export const getKejagung = createAsyncThunk(
    "orgstructure/getKejagung",
    async (params) => {
        const response = await orgAPI.getOrgStructure({
            is_structure: true,
            workunit_level_id: 1
        })
        return response?.data
    }
)

export const getKejati = createAsyncThunk(
    "orgstructure/getKejati",
    async (params) => {
        const response = await orgAPI.getOrgStructure({
            is_structure: true,
            workunit_level_id: 2
        })
        return response?.data
    }
)

export const getKejari = createAsyncThunk(
    "orgstructure/getKejari",
    async (params) => {
        const response = await orgAPI.getOrgStructure({
            is_structure: true,
            workunit_level_id: 3
        })
        return response?.data
    }
)

export const getCabjari = createAsyncThunk(
    "orgstructure/getCabjari",
    async (params) => {
        const response = await orgAPI.getOrgStructure({
            is_structure: true,
            workunit_level_id: 4
        })
        return response?.data
    }
)

export const orgStructureSlice = createSlice({
    name: "orgstructures",
    initialState: {
        kejagung: [],
        kejati: [],
        kejari: [],
        cabjari: [],
        selected: null
    },
    reducers: {
        selectOrgStructure: (state, action) => {
            if (action.payload === null) {
                state.selected = null
            } else {
                state.selected = action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getKejagung.fulfilled, (state, action) => {
                state.kejagung = action.payload
            })
            .addCase(getKejati.fulfilled, (state, action) => {
                state.kejati = action.payload
            })
            .addCase(getKejari.fulfilled, (state, action) => {
                state.kejari = action.payload
            })
            .addCase(getCabjari.fulfilled, (state, action) => {
                state.cabjari = action.payload
            })
    }
})

export const { selectOrgStructure } = orgStructureSlice.actions

export default orgStructureSlice.reducer
